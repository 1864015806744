import firebase from '../firebase';

export const setProperties = (userId, userName) => {
  const analytics = firebase.analytics();

  analytics.setUserId(userId);
  analytics.setUserProperties({
    userName,
    platform: 'ZEEBLY_ADMIN',
  });
};

export const resetProperties = () => {
  const analytics = firebase.analytics();

  analytics.setUserId('N/A');
  analytics.setUserProperties({
    userName: 'N/A',
    platform: 'ZEEBLY_ADMIN',
  });
};

export const logAnalyticEvent = (eventName) => {
  firebase.analytics().logEvent(eventName);
};

export const logAnalyticEventQuery = (criteria, name) => {
  const analytics = firebase.analytics();

  analytics.logEvent(name, { ...criteria });
};

export const orderDateFilter = (criteria) => {
  const analytics = firebase.analytics();

  analytics.logEvent('order_date_filter', { ...criteria });
};

export const orderWarehouseFilter = (criteria) => {
  const analytics = firebase.analytics();

  analytics.logEvent('order_warehouse_filter', { ...criteria });
};

export const downloadOredersType = (criteria) => {
  const analytics = firebase.analytics();

  analytics.logEvent('download_oreders_type', { ...criteria });
};

export const searchUserQuery = (criteria) => {
  const analytics = firebase.analytics();

  analytics.logEvent('search_user_query', { ...criteria });
};

export const countryUserQuery = (criteria) => {
  const analytics = firebase.analytics();

  analytics.logEvent('country_user_query', { ...criteria });
};

export const terminalDateFilter = (criteria) => {
  const analytics = firebase.analytics();

  analytics.logEvent('terminal_date_filter', { ...criteria });
};
