// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_styledBtn__1_uGX .style_MuiButton-label__PBbti {\n    line-height: normal;\n}\n\n.style_icon__Feldd {\n    width: 100%;\n    min-width: 1.25rem;\n}\n\n.style_marginRight__7gkbQ {\n    margin-right: 1rem !important;\n}\n\n.style_loader__1xFft {\n    margin-left: 1rem;\n}", "",{"version":3,"sources":["webpack://src/components/Button/style.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".styledBtn .MuiButton-label {\n    line-height: normal;\n}\n\n.icon {\n    width: 100%;\n    min-width: 1.25rem;\n}\n\n.marginRight {\n    margin-right: 1rem !important;\n}\n\n.loader {\n    margin-left: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styledBtn": "style_styledBtn__1_uGX",
	"MuiButton-label": "style_MuiButton-label__PBbti",
	"icon": "style_icon__Feldd",
	"marginRight": "style_marginRight__7gkbQ",
	"loader": "style_loader__1xFft"
};
export default ___CSS_LOADER_EXPORT___;
