// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_uploadingContainer__LsiMa {\n    width: 100%;\n    padding: 1rem;\n    box-sizing: border-box;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.style_uploadingIcon__13ome {\n    margin-bottom: 1.25rem;\n}\n\n.style_uploadingTitle__2D6Hs {\n    font-size: 1.25rem;\n    font-weight: 500;\n    margin-bottom: 0.75rem;\n}\n", "",{"version":3,"sources":["webpack://src/components/ProgressDialog/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;;IAEtB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;AAC1B","sourcesContent":[".uploadingContainer {\n    width: 100%;\n    padding: 1rem;\n    box-sizing: border-box;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.uploadingIcon {\n    margin-bottom: 1.25rem;\n}\n\n.uploadingTitle {\n    font-size: 1.25rem;\n    font-weight: 500;\n    margin-bottom: 0.75rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadingContainer": "style_uploadingContainer__LsiMa",
	"uploadingIcon": "style_uploadingIcon__13ome",
	"uploadingTitle": "style_uploadingTitle__2D6Hs"
};
export default ___CSS_LOADER_EXPORT___;
