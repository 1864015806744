// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_customersUploadContainer__2gdMb {\n    width: 100%;\n    padding: 1rem;\n    box-sizing: border-box;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.style_icon__3CaWC {\n    margin-bottom: 1.25rem;\n}\n\n.style_title__2rUIn {\n    font-size: 1.25rem;\n    font-weight: 500;\n    margin-bottom: 0.75rem;\n}\n\n.style_description__mfDvG {\n    color: var(--accent-light);\n    text-align: center;\n    margin-bottom: 0.75rem;\n}", "",{"version":3,"sources":["webpack://src/components/ErrorDialog/style.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;;IAEtB,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,sBAAsB;AAC1B","sourcesContent":[".customersUploadContainer {\n    width: 100%;\n    padding: 1rem;\n    box-sizing: border-box;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: center;\n}\n\n.icon {\n    margin-bottom: 1.25rem;\n}\n\n.title {\n    font-size: 1.25rem;\n    font-weight: 500;\n    margin-bottom: 0.75rem;\n}\n\n.description {\n    color: var(--accent-light);\n    text-align: center;\n    margin-bottom: 0.75rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customersUploadContainer": "style_customersUploadContainer__2gdMb",
	"icon": "style_icon__3CaWC",
	"title": "style_title__2rUIn",
	"description": "style_description__mfDvG"
};
export default ___CSS_LOADER_EXPORT___;
