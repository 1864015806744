// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_dialog__2AAzj {\n    position: relative;\n}\n\n.style_closeBtn__1GxK0 {\n    position: absolute !important;\n    top: 0.5rem;\n    right: 0.5rem;\n}\n.style_padding__1S2uD {\n    padding: 1rem !important;\n}\n.style_noPadding__1TZUD {\n    padding: 0 !important;\n}\n.style_noTopPadding__2GDFq{\npadding-top: 0.2rem !important;\n}\n.style_container__IzoCq {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: stretch;\n}\n.style_message__2fMFz {\n    margin-left: 1.5rem;\n}\n\n", "",{"version":3,"sources":["webpack://src/components/Dialog/style.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,WAAW;IACX,aAAa;AACjB;AACA;IACI,wBAAwB;AAC5B;AACA;IACI,qBAAqB;AACzB;AACA;AACA,8BAA8B;AAC9B;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,oBAAoB;AACxB;AACA;IACI,mBAAmB;AACvB","sourcesContent":[".dialog {\n    position: relative;\n}\n\n.closeBtn {\n    position: absolute !important;\n    top: 0.5rem;\n    right: 0.5rem;\n}\n.padding {\n    padding: 1rem !important;\n}\n.noPadding {\n    padding: 0 !important;\n}\n.noTopPadding{\npadding-top: 0.2rem !important;\n}\n.container {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-start;\n    align-items: stretch;\n}\n.message {\n    margin-left: 1.5rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": "style_dialog__2AAzj",
	"closeBtn": "style_closeBtn__1GxK0",
	"padding": "style_padding__1S2uD",
	"noPadding": "style_noPadding__1TZUD",
	"noTopPadding": "style_noTopPadding__2GDFq",
	"container": "style_container__IzoCq",
	"message": "style_message__2fMFz"
};
export default ___CSS_LOADER_EXPORT___;
