import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#e5eef5',
      main: '#8A50F6',
      dark: '#8A50F6',
    },
  },
  typography: {
    fontFamily: "'Circular Std', 'sans-serif'",
  },
  spacing: 5,
});

export default theme;
